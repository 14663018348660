<template>
  <section class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <h1 class="headline">Animation</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AnimationFun',
  title: 'Carly Bergthold - Animation'
}
</script>

<style scoped>
.headline {
  color: white;
  margin-top: 8rem;
  font-size: 4rem;
  font-weight: 800;
}
</style>